/* App.css */
:root {
  --smalt-blue: rgba(75, 127, 133, 1);
  --rose-fog: rgba(225, 185, 176, 1);
  --venus: rgba(149, 143, 145, 1);
  /* --ebony-clay: rgba(32, 38, 52, 1); */
  --mortar: rgba(69, 56, 72, 1);
  --half-baked1: rgba(129, 198, 183, 1);
  --half-baked2: rgba(145, 200, 200, 1);
  --nile-blue: rgba(27, 73, 89, 1);
  --santa-fe: rgba(188, 106, 90, 1);
}

.gradientBackground {
  background: linear-gradient(45deg,
      var(--smalt-blue),
      var(--rose-fog),
      var(--venus),
      /* var(--ebony-clay),  */
      var(--mortar),
      var(--half-baked1),
      var(--half-baked2),
      var(--nile-blue),
      var(--santa-fe));
  background-size: 200% 200%;
  animation: gradient-animation 20s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  text-align: center;
}

.iconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.nameAndSubtitle {
  /* font-size: 3rem; */
  font-weight: 300;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}