/* TitleCarousel.css */
.fade {
    opacity: 0;
    transition: opacity 2s;
}

.fadeIn {
    opacity: 1;
    transition: opacity 2s;
}

.titleStyle{
    font-size: 3rem;
    font-weight: 500;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
}